import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
/** Import some prebuilt components */
import { H1, H2, H3, H4, H5 } from './Heading';
import { Paragraph, OL, UL /*  LI, Span */ } from './Text';
import Link from './Links';
import Image from './Image';
import Tweet from './Tweet/Tweet';
import Radial from './Radial';
import Tooltip from './Tooltip/Tooltip';
import Counter from './Counter';
import Flutter from './Flutter';
import CollapsibleContent from './CollapsibleContent/CollapsibleContent';

/**
 * Our default Markdown parser. This can and should be overriden in the theme
 * with whatever extra components and styles are needed. The components included
 * here are built-in lightpaper components
 */
const MarkdownParser = props => (
  <Markdown
    options={{
      overrides: {
        h1: { component: H1 },
        h2: { component: H2 },
        h3: { component: H3 },
        h4: { component: H4 },
        h5: { component: H5 },
        H1: { component: H1 },
        H2: { component: H2 },
        H3: { component: H3 },
        H4: { component: H4 },
        H5: { component: H5 },
        p: { component: Paragraph },
        Paragraph: { component: Paragraph },
        ol: { component: OL },
        ul: { component: UL },
        // li: { component: LI },
        // span: { component: Span },
        // Span: { component: Span },
        span: { component: Paragraph },
        Span: { component: Paragraph },
        a: { component: Link },
        Link: { component: Link },
        img: { component: Image },
        Image: { component: Image },
        Tweet: { component: Tweet },
        Radial: { component: Radial },
        Tooltip: { component: Tooltip },
        Counter: { component: Counter },
        Flutter: { component: Flutter },
        Collapsible: { component: CollapsibleContent },
        /** Add any additional overrides here */
        ...props.overrides,
      },
    }}
  >
    {props.children}
  </Markdown>
);

MarkdownParser.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MarkdownParser;
