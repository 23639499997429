import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/macro';
import Radial from '../Radial';
import C from '../Container';

const Block = styled.div`
  max-width: 100%;
  margin: 2em;
  vertical-align: top;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
  @media ${props => props.theme.breakpoints.small} {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

const Container = styled(C)`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  */margin: 0 auto;
`;

const RadialOuter = styled.div`
  width: 100%;
  margin-bottom: 0.5em;
  max-width: 18em;
  color: ${({ theme }) => theme.colors.brightComplement};
  margin: 0 auto;
  @media ${props => props.theme.breakpoints.small} {
    margin: 0 auto 0.5em;
    width: 100%;
  }
`;

const IntroStyled = styled.div`
  margin: 0;
  width: initial;
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 700;
  margin: 1.2em auto 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutralDark};

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    margin: 1em auto 0.5em;
    text-align: center;
  }
`;

const RadialGraph = ({ fields, theme, ...rest }) => {
  const { number, caption } = fields;

  return (
    <Block isLastItem={rest.index + 1 === rest.numberOfBlocks}>
      <Container>
        <RadialOuter>
          <Radial
            barColor={theme.colors.brightPrimaryComplement}
            trackColor={theme.colors.brightPrimary}
            data={String(number)}
          />
        </RadialOuter>
        <IntroStyled>{caption}</IntroStyled>
      </Container>
    </Block>
  );
};

RadialGraph.propTypes = {
  fields: PropTypes.shape({
    caption: PropTypes.string,
    number: PropTypes.number,
  }).isRequired,
};

export default withTheme(RadialGraph);
