import styled from 'styled-components/macro';

/** Set up default heading elements */

export const H1 = styled.h1`
  margin: 0 0 1em;
  font-size: 8.6rem;
  line-height: 1.14;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.white};
`;

export const H2 = styled.h2`
  margin: 0 0 0.3em;
  font-size: 6rem;
  line-height: 1.2;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 300;
`;

export const H3 = styled.h3`
  margin: 0 0 0.1em;
  font-size: 4.8rem;
  line-height: 1.25;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.brightPrimary};
  font-weight: 400;
`;

export const H4 = styled.h4`
  margin: 0 0 0.1em;
  font-size: 3.4rem;
  line-height: 1.35;
  font-weight: normal;
  color: ${({ theme, color }) =>
    !!color ? theme.colors?.[color] : theme.colors.onyneutralDarkx};
  font-weight: 700;
`;

export const H5 = styled.h4`
  margin: 0 0 0.1em;
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.brightPrimary};
`;

export const H6 = styled.h4`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.6;
`;

export const Eyebrow = styled(H4)`
  color: inherit;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.14;
  text-transform: uppercase;
  margin-bottom: 2em;
`;
