import Hero from '../components/blocks/Hero';
import Callout from '../components/blocks/Callout';
import CaseStudy from '../components/blocks/CaseStudy';
import Code from '../components/blocks/Code';
import Counter from '../components/blocks/Counter';
import Default from '../components/blocks/Default';
import Grid from '../components/blocks/Grid';
import Radial from '../components/blocks/Radial';
import Media from '../components/blocks/Media';
import SectionTitle from '../components/blocks/SectionTitle';
import TableOfContents from '../components/blocks/TableOfContents';
import Video from '../components/blocks/Video';

/**
 * Define default blocks available for sections, matching the content type's
 * machine name and the actual component name. If you have a content type
 * defined in the content (remote or local), it must have a block associated
 * with it or it won't display.
 *
 * For example, if you add a custom callout content type, you'll need a Callout
 * component, and it needs to be defined here as { callout: Callout }
 */
export default {
  hero: Hero,
  callout: Callout,
  caseStudy: CaseStudy,
  code: Code,
  content: Default,
  counter: Counter,
  grid: Grid,
  media: Media,
  radialChart: Radial,
  sectionTitle: SectionTitle,
  tableOfContents: TableOfContents,
  video: Video,
};
