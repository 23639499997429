import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { /* keyframes,*/ css } from 'styled-components/macro';
import Waypoint from '../Waypoint';
import Container from '../Container';
import Img from '../Image';

const MediaBlock = styled.div`
  width: 100%;
  margin: ${props => (props.margin ? '0' : '2em')} auto;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
`;

const ImageContainer = styled.div`
  /* display: flex;
  justify-content: ${props => props.alignment}; */
`;

const StyledSvg = styled.div`
  width: ${props => props.size};
  margin: 0 auto;
  ${({ alignment }) =>
    alignment && alignment.toLowerCase() === 'left'
      ? css`
          margin-left: -8rem;
          float: left;

          @media screen and (max-width: ${props =>
              props.theme.breakpoints.medium}) {
            float: none;
            margin: 0 auto 2rem;
          }
        `
      : alignment && alignment.toLowerCase() === 'right'
      ? css`
          margin-right: -8rem;
          float: right;

          @media screen and (max-width: ${props =>
              props.theme.breakpoints.medium}) {
            float: none;
            margin: 0 auto 2rem;
          }
        `
      : null} svg {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
`;

const Image = styled(Img)`
  width: ${props => props.size};
  margin: 0 auto;
  ${props =>
    props.alignment && props.alignment.toLowerCase() === 'left'
      ? css`
          // margin: 0 2rem 2rem -${
            props.size ? `calc(${props.size})/2` : `4rem`
          };
          margin: 0 2rem 2rem -2rem;
          float: left;

          @media screen and (max-width: ${props =>
            props.theme.breakpoints.medium}) {
            float: none;
            margin: 0 auto 2rem;
          }
        `
      : props.alignment && props.alignment.toLowerCase() === 'right'
      ? css`
          // margin: 0 -${props.size ? `calc(${props.size})/2` : `4rem`} 2rem 0;
          margin: 0 -2rem 2rem 2rem;
          float: right;

          @media screen and (max-width: ${props =>
            props.theme.breakpoints.medium}) {
            float: none;
            margin: 0 auto 2rem;
          }
        `
      : null};
`;

const Media = ({ fields, isNested, ...rest }) => {
  const { image, width, alignment, removeContainer, animateLines } = fields;
  const [svg, getSvg] = useState('');
  const [shouldAnimate, setAnimate] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await fetch(image)
        .then(response => response.text())
        .then(body => getSvg(body));
    }
    image && fetchData();
  }, [image]);

  const alt = image.fields && (image.fields.description || image.fields.title);

  return (
    <Waypoint
      topOffset="0"
      bottomOffset="35%"
      onEnter={() => setAnimate(true)}
      onLeave={() => setAnimate(false)}
    >
      <MediaBlock
        margin={removeContainer}
        isLastItem={rest.index + 1 === rest.numberOfBlocks}
      >
        <Container fullWidth={removeContainer}>
          <ImageContainer>
            {image?.fields?.file?.contentType === 'image/svg+xml' ? (
              <StyledSvg
                alt={alt}
                alignment={alignment}
                size={width}
                animateLines={animateLines}
                shouldAnimate={shouldAnimate}
                dangerouslySetInnerHTML={{ __html: svg }}
              />
            ) : (
              <Image src={image} alt={alt} alignment={alignment} size={width} />
            )}
          </ImageContainer>
        </Container>
      </MediaBlock>
    </Waypoint>
  );
};

Media.propTypes = {
  fields: PropTypes.shape({
    image: PropTypes.string.isRequired,
    width: PropTypes.string,
    justifyContent: PropTypes.string,
  }).isRequired,
  isNested: PropTypes.bool,
};

Media.defaultProps = {
  fields: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  isNested: true,
};

export default Media;
