import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';
import { Paragraph } from '../Text';
import { ReactComponent as OpenQuoteSvg } from '../../assets/quote-open.svg';
import { ReactComponent as CloseQuoteSvg } from '../../assets/quote-close.svg';

const QuoteStyles = css`
  position: absolute;
  color: ${({ theme }) => theme.colors.brightPrimaryComplement};
  z-index: -1;
  z-index: 1;
  pointer-events: none;
  width: 7rem;
  height: auto;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    width: 5rem;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    width: 4rem;
  }
`;

const OpenQuote = styled(OpenQuoteSvg)`
  ${QuoteStyles}
  top: -2.5em;
  left: -4.8em;

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    top: -1.5em;
    left: -2.8em;
  }
`;

const CloseQuote = styled(CloseQuoteSvg)`
  ${QuoteStyles}
  bottom: -2.5em;
  right: -1em;
`;

const CalloutBlock = styled.div`
  margin: 8em 2em 4em;
  font-size: 1.2em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.brightSecondary};

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    margin: 5em 0 3em;
  }
`;

const StyledContainer = styled(Container)`
  padding: 0.5em 8em;

  @media screen and (max-width: ${props => props.theme.breakpoints.large}) {
    padding: 0.5em 6em;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    padding: 0.5em 4em;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    padding: 0.5em 1em;
  }
`;

const QuoteContainer = styled.div`
  position: relative;
`;

const CalloutText = styled(Paragraph)`
  font-size: 2.4rem;
  line-height: 1.67;
  color: inherit;
  font-weight: 700;
  margin-bottom: 2em;
  position: relative;
  z-index: 2;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 2.2rem;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    font-size: 2rem;
  }
`;

const Attribution = styled(Paragraph)`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.43;
  font-style: italic;
`;

/** The most basic type of block we can render */
const Callout = ({ fields, ...rest }) => {
  const { content, attribution } = fields;

  return (
    <CalloutBlock>
      <StyledContainer>
        <QuoteContainer>
          <OpenQuote />
          <CloseQuote />
          <MarkdownParser
            overrides={{
              p: CalloutText,
              span: CalloutText,
            }}
          >
            {content}
          </MarkdownParser>
        </QuoteContainer>
        {/* <Attribution>{attribution}</Attribution> */}
        <MarkdownParser
          overrides={{
            span: Attribution,
            p: Attribution,
          }}
        >
          {attribution}
        </MarkdownParser>
      </StyledContainer>
    </CalloutBlock>
  );
};

Callout.propTypes = {
  fields: PropTypes.shape({
    /** Body is the only required child of fields */
    content: PropTypes.string.isRequired,
  }).isRequired,
};

export default Callout;
