import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

/**
 * Generate meta and other head information for each section (route) of the app.
 *
 * Since all the initial meta tags are defined in SectionHead, we only need to
 * provide a new title and description per section
 */
const SectionHead = ({ title, description, pageIndex }) => (
  <Helmet>
    {title && (
      <title>
        {pageIndex !== 0 ? `${title} - ` : ''}Real-World Learning for Frontline
        Workers | Schoox
      </title>
    )}
    {description && <meta name="description" content={description} />}
  </Helmet>
);

SectionHead.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

SectionHead.defaultProps = {
  title: null,
  description: null,
};

export default SectionHead;
