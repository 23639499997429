import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';

const DefaultBlock = styled.div``;

/** The most basic type of block we can render */
const Default = ({ fields }) => {
  const { body } = fields;
  return (
    <DefaultBlock>
      <Container>
        <MarkdownParser>{body}</MarkdownParser>
      </Container>
    </DefaultBlock>
  );
};

Default.propTypes = {
  fields: PropTypes.shape({
    /** Body is the only required child of fields */
    body: PropTypes.string.isRequired,
  }).isRequired,
};

export default Default;
