import React from 'react';
import Container from '../Container';

const Code = props => {
  const { fields } = props;

  return (
    <Container>
      <div dangerouslySetInnerHTML={{ __html: fields.code.code }} />
    </Container>
  );
};

export default Code;
