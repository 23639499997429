import styled, { css } from 'styled-components/macro';

export const ParagraphStyles = css`
  font-size: 2rem;
  line-height: 1.6;
  font-weight: normal;
  margin: 0 0 1.8em;
  /* color: #373737;
  color: ${function(props) {
    return props.theme.colors.neutralDark;
  }};
  color: ${props => props.theme.colors.neutralDark}; */
  color: ${({ theme }) => theme.colors.neutralDark};
`;

/** Set up default text block elements */
export const Paragraph = styled.p`
  ${ParagraphStyles};
`;

export const UL = styled.ul`
  ${ParagraphStyles}

  list-style: none;
  padding-left: 1.25em;

  li {
    position: relative;

    &:before {
      content: '• ';
      color: ${({ theme }) => theme.colors.brightPrimaryComplement};
      font-size: 0.8em;
      position: absolute;
      left: -1.15em;
      top: -0.425em;
      height: 6px;
      width: 6px;
      margin-top: 0.6em;
    }
  }
`;

export const OL = styled.ul`
  ${ParagraphStyles};
`;

export const LI = styled.li``;

export const Span = styled.span`
  ${ParagraphStyles};
`;
