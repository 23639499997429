/** Define common colors, fonts, and other branding here */
const colors = {
  // white: '#ffffff',
  black: '#000000',
  // onyx: '#373737',
  // schooxBlue: '#009bdf', brightprimary
  // schooxGreen: '#94d600', brightPrimaryComplement
  // schooxGray: '#77787b',
  // darkBlue: '#016b9f', brightSecondary
  // darkerBlue: '#06516f', brightComplement
  // darkGreen: '#81b448',

  white: '#f7f8fc',
  grey: '#dad9e6',
  neutralDark: '#1b1e47',
  logoText: '#2a2657',
  brightPrimary: '#9571b2',
  brightSecondary: '#6d6fb3',
  brightComplement: '#49bad2',
  brightPrimaryComplement: '#cb74af',
  errorRed: '#db6194',
};

/** Light theme for each section */
const light = {
  pageColor: colors.white,
};

const dark = {
  pageColor: colors.grey,
};

const fonts = {
  serif: 'serif',
  sansSerif: "'Helvetica Neue', sans-serif",
};

const breakpoints = {
  small: '35em',
  medium: '64em',
  medLarge: '71em',
  large: '96em',
  xlarge: '110em',
};

const animations = {
  duration: '0.3s',
  easing: 'ease-out',
};

const theme = {
  colors,
  fonts,
  breakpoints,
  animations,
  light,
  dark,
};

export default theme;
