import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

const StyledLinkBare = styled.a`
  text-decoration: none;
  color: inherit;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.brightPrimary};
`;

const StyledButton = styled.a`
  appearance: none;
  border: 0;
`;

const useLink = props => {
  /**
   * Determine if a link is external by constructing a URL object from the prop,
   * then comparing the hostname of the URL with the window's hostname. If the
   * `href` prop is a relative link like '/test', the window.location will be
   * used as a base. If it's an absolute URL like 'https://google.com', the base
   * will be ignored.
   *
   * Also add in a special check for the public/assets folder
   */
  const isExternal =
    new URL(props.href, window.location).hostname !==
      window.location.hostname || props.href.substring(0, 8) === '/assets/';

  const attrs = isExternal
    ? /** Add extra attributes for external links */
      {
        href: props.href,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : /** Internal Link components need the "to" prop instead of "href" */
      {
        to: props.href,
      };

  return [attrs, isExternal];
};

/** Links that look like buttons */
export const Button = props => {
  const [attrs, isExternal] = useLink(props);
  return (
    <StyledButton
      {...props}
      {...attrs}
      as={isExternal ? undefined : Link}
      className={props.className}
    />
  );
};

/** An unstyled link, good for wrapping images */
export const LinkBare = props => {
  const [attrs, isExternal] = useLink(props);
  return (
    <StyledLinkBare
      {...props}
      {...attrs}
      /** If the link is internal, use a react-router Link */
      as={isExternal ? undefined : Link}
      /** Add the className so any other components can override the styling */
      className={props.className}
    >
      {props.children}
    </StyledLinkBare>
  );
};

/** Standard text links */
export default props => {
  const [attrs, isExternal] = useLink(props);
  return (
    <StyledLink
      {...props}
      {...attrs}
      /** If the link is internal, use a react-router Link */
      as={isExternal ? undefined : Link}
      /** Add the className so any other components can override the styling */
      className={props.className}
    />
  );
};
