import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';
import { H1, H3 } from '../Heading';

export const HeroWrapper = styled.div``;

const HeroStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height: 60vh;
  background-image: ${props =>
    props.backgroundImage ? `url(${props.backgroundImage})` : null};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 75% 75%;
  margin: 4em auto;
  padding: 4em 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    margin-bottom: 8em;
    padding: 4em 0 0;
  }
`;

const TextWrapper = styled.div`
  padding: 0 0 3em;
`;

const Title = styled(H1)`
  color: ${({ theme }) => theme.colors.white};
  margin-top: 1em;
  margin-bottom: 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    // width: 75%;
    margin-top: 2em;
    margin-bottom: 0.5em;
    padding: 0;
    font-size: 5.6rem;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    padding: 0;
    font-size: 4.8rem;
  }
`;

const Subtitle = styled(H3)`
  color: ${({ theme }) => theme.colors.white};

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    margin-top: 0;
    margin-bottom: 0.5em;
    padding: 0;
    font-size: 3.8rem;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    padding: 0.4em;
    font-size: 3.2rem;
    padding: 0;
  }
`;

const HorizontalRule = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.brightPrimaryComplement};
  margin: 4em auto;
`;

const Hero = ({ fields }) => {
  const { title, backgroundImage } = fields;
  return (
    <HeroWrapper>
      <HeroStyled backgroundImage={backgroundImage || null}>
        <Container size="wide">
          <TextWrapper>
            <MarkdownParser
              overrides={{
                h1: Title,
                h3: Subtitle,
                hr: HorizontalRule,
              }}
            >
              {title}
            </MarkdownParser>
          </TextWrapper>
        </Container>
      </HeroStyled>
    </HeroWrapper>
  );
};

Hero.propTypes = {
  fields: PropTypes.shape({
    body: PropTypes.string,
    backgroundImage: PropTypes.string,
  }).isRequired,
};

Hero.defaultProps = {
  fields: {
    body: '',
    backgroundImage: null,
  },
};

export default Hero;
