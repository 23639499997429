import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { transparentize } from 'polished';
import contentBlocks from '../../config/contentBlocks';
import Container, { containerSizes } from '../Container';
import { Eyebrow } from '../Heading';
import MarkdownParser from '../MarkdownParser';
import { UL } from '../Text';
import Image from '../Image';

const { wide: containerWide, default: containerDefault } = containerSizes;

const CaseStudyContainer = styled.div`
  border-top: ${({ theme }) =>
    `18px solid ${theme.colors.brightPrimaryComplement}`};
  box-shadow: 0px 4px 30px
    ${({ theme }) => transparentize(0.75, theme.colors.black)};
  padding: ${() => `9em calc((${containerWide} - ${containerDefault}) / 2)`};
  margin: 10em auto 0;
  background-color: ${({ theme }) => theme.colors.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 10em 2em 0;
    padding: ${() =>
      `4em calc(((${containerWide} - ${containerDefault}) / 2) - 4em)`};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 10em 2em 0em;
    padding: ${() =>
      `4em calc(((${containerWide} - ${containerDefault}) / 2) - 4em)`};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin: 10em -1em 0;
    padding: 4em 2em;
  }
`;

const HeadingGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column-reverse;
  }
`;

const HeadingTextContainer = styled.div`
  flex: 1 1 0;
`;

const HeadingIconContainer = styled.div`
  width: 10.8em;
  text-align: right;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-bottom: 4rem;
  }
`;

const CaseStudyEyebrow = styled(Eyebrow)`
  margin-bottom: 1.5rem;
`;

const HeadingList = styled(UL)`
  list-style: none;
  padding-left: 0;
`;

const HeadingListItem = styled(Eyebrow).attrs({
  as: 'li',
})`
  display: inline-block;
  margin-right: 2em;

  &:before {
    content: '' !important;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const HeadingIcon = styled(Image)`
  height: auto;
`;

const CaseStudyHeading = props => {
  const { caseStudyTitle, icon, industry, size } = props;
  return (
    <HeadingGroup>
      <HeadingTextContainer>
        <CaseStudyEyebrow>Case Study</CaseStudyEyebrow>
        {caseStudyTitle && <MarkdownParser>{caseStudyTitle}</MarkdownParser>}
        <HeadingList>
          {industry && <HeadingListItem>Industry: {industry}</HeadingListItem>}
          {size && <HeadingListItem>Size: {size} Learners</HeadingListItem>}
        </HeadingList>
      </HeadingTextContainer>
      <HeadingIconContainer>
        <HeadingIcon src={icon} />
      </HeadingIconContainer>
    </HeadingGroup>
  );
};

const CaseStudy = ({ fields, ...props }) => {
  const { collection, caseStudyHeading } = fields;

  return (
    <Container size="wide">
      <CaseStudyContainer>
        <CaseStudyHeading {...caseStudyHeading} />

        {collection &&
          collection.length &&
          collection.map((collectionItem, i) => {
            const itemContentType = collectionItem?.type;
            if (!itemContentType) {
              return false;
            }

            const ContentBlock = contentBlocks[itemContentType];

            return !!ContentBlock ? (
              <ContentBlock
                key={
                  collectionItem?.adminTitle ??
                  `case-study-item-${collectionItem.type}-${i}`
                }
                fields={collectionItem}
                fullWidth
                isNested
              />
            ) : null;
          })}
      </CaseStudyContainer>
    </Container>
  );
};

CaseStudy.propTypes = {
  fields: PropTypes.shape({
    adminTitle: PropTypes.string,
    collection: PropTypes.array,
    displayTheTitle: PropTypes.bool,
    itemsPerRow: PropTypes.number,
    itemAlignment: PropTypes.string,
  }).isRequired,
};

CaseStudy.defaultProps = {
  fields: {
    adminTitle: '',
    collection: [],
    displayTheTitle: false,
    itemsPerRow: 3,
    itemAlignment: 'Top',
    fullWidth: false,
    showSeparator: false,
  },
};

export default CaseStudy;
