import styled, { keyframes, css } from 'styled-components/macro';
import { transparentize } from 'polished';

/** Animate the header sliding in when it becomes stuck */
const stick = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
`;

const stickAnimation = css`
  ${stick} 0.19s linear;
`;

/** Set the standard header styles, including when it's sticky */
export default styled.header`
  /** When it's above the first section, let the section bleed under it */
  position: ${props => {
    if (props.isStuck) {
      return 'fixed';
    }
    if (!props.isUnderFirstBlock) {
      return 'absolute';
    }
    return 'static';
  }};
  z-index: 1000;
  top: ${props => (props.isStuck ? '0em' : '4em')};

  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  /** Don't run the animation if it's under the first section */
  animation: ${props =>
    props.isStuck && !props.isUnderFirstBlock ? stickAnimation : null};
  box-shadow: 0px 0px 20px 3px
    ${({ theme }) => transparentize(0.88, theme.colors.neutralDark)};
`;
