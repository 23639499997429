import React from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { transitions } from 'polished';
import Container from '../Container';
import { H4 } from '../Heading';
import { UL, Span } from '../Text';
import { ReactComponent as No1 } from '../../assets/numbers/1.svg';
import { ReactComponent as No2 } from '../../assets/numbers/2.svg';
import { ReactComponent as No3 } from '../../assets/numbers/3.svg';
import { ReactComponent as No4 } from '../../assets/numbers/4.svg';
import { ReactComponent as No5 } from '../../assets/numbers/5.svg';
import MarkdownParser from '../MarkdownParser';

const ACTIVE_CLASS_NAME = 'active-section';

const NUMBERS = [No1, No2, No3, No4, No5];

const TocList = styled(UL)`
  list-style: none;
  padding-left: 0;
  margin-bottom: 6em;
`;

const NumberContainer = styled.div`
  // width: 10%;
  width: 5.65rem;
  margin-right: 1.6em;
  color: ${({ theme }) => theme.colors.brightComplement};

  svg {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
`;

const TocTitle = styled(H4).attrs({
  as: 'span',
})`
  display: block;
  color: ${({ theme }) => theme.colors.brightPrimary};
  margin-bottom: 0.85em;

  ${({ theme }) =>
    transitions(
      ['color'],
      `${theme.animations.duration} ${theme.animations.easing}`
    )};
`;

const TocListItem = styled.li`
  padding: 1.8em 0;

  &:before {
    content: '' !important;
  }

  &:not(:last-child) {
    border-bottom: 1.5px solid
      ${({ theme }) => theme.colors.brightPrimaryComplement};
  }

  &:hover {
    ${TocTitle} {
      color: ${({ theme }) => theme.colors.brightSecondary};
    }
  }
`;

const TocSubtitle = styled(Span)`
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.14;
  text-transform: uppercase;
  display: block;
  color: ${({ theme }) => theme.colors.brightComplement};
`;

const NavItem = styled(NavLink).attrs({
  activeClassName: ACTIVE_CLASS_NAME,
  exact: true,
})`
  display: flex;
  text-decoration: none;
  line-height: 1.4;
  margin-bottom: 0.5em;
  color: inherit;
`;

const TableOfContentsItem = ({
  title = null,
  subtitle = null,
  pageSlug = null,
  index,
}) => {
  const NumberComponent = NUMBERS[index];
  return (
    <TocListItem>
      <NavItem to={pageSlug}>
        <NumberContainer>
          <NumberComponent />
        </NumberContainer>
        <div>
          {title && <TocTitle>{title}</TocTitle>}
          {/* {subtitle && <TocSubtitle>{subtitle}</TocSubtitle>} */}
          {subtitle && (
            <MarkdownParser
              overrides={{
                span: TocSubtitle,
                p: TocSubtitle,
              }}
            >
              {subtitle}
            </MarkdownParser>
          )}
        </div>
      </NavItem>
    </TocListItem>
  );
};

const TableOfContents = ({ fields }) => {
  const { pages } = fields;

  return !!pages && Array.isArray(pages) ? (
    <Container>
      <TocList>
        {pages.map((page, i) => (
          <TableOfContentsItem key={`TOC-${page.title}`} index={i} {...page} />
        ))}
      </TocList>
    </Container>
  ) : (
    <>{console.log('add pages to the Table of Contents.')}</>
  );
};

export default TableOfContents;
