/** A place to put miscellaneous config options */
export default {
  shouldUseRemoteContent: false,
  contentful: {
    // space: 'vau5767s43wg',
    space: '',
    accessToken: '',
  },
  hubspot: {
    formId: '9c6e41a8-2c5a-4d4f-b3ed-b444041e6acc',
    portalId: '6467984',
    sfdcCampaignId: '7017R000001v5f1QAA',
  },
  isPaged: false,
  isHeaderUnderFirstBlock: false,
  /** 'dot' or 'full' */
  nav: 'dot',
  twitterHandle: 'schooX',
};
/**
<script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>
<script>
 hbspt.forms.create({
  region: "na1",
  portalId: "6467984",
  formId: "9c6e41a8-2c5a-4d4f-b3ed-b444041e6acc",
  sfdcCampaignId: "7017R000001v5f1QAA"
 });
</script>
 */
