import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import contentBlocks from '../../config/contentBlocks';
import Container from '../Container';
import { H3 } from '../Heading';

const GridBlock = styled.div`
  margin: 5em auto 4em;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
`;

const GridContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 -1.5em;
  align-items: flex-start;
  ${props =>
    props.itemAlignment &&
    props.itemAlignment.toLowerCase() === 'center' &&
    'align-items: center;'};
  ${props =>
    props.itemAlignment &&
    props.itemAlignment.toLowerCase() === 'bottom' &&
    'align-items: flex-end;'};

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    flex-flow: column nowrap;
  }
`;

const GridItem = styled.div`
  /* display: flex; */
  width: ${props => (1 / props.itemsPerRow) * 100}%;
  ${props => props.itemWidth && 'width: ' + props.itemWidth};
  flex: 0 0 auto;
  padding: 0 1.5em;
  /* margin: 0 auto 2em; */

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    width: 100%;
    margin-bottom: 5em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  *:first-child {
    margin-top: 0;
  }

  ${
    '' /* margin-left: ${props => (props.index % props.itemsPerRow === 0 ? 0 : null)};
  margin-right: ${props =>
    (props.index + 1) % props.itemsPerRow === 0 ? 0 : null}; */
  }

  /** Remove top spacing from the first row */
  ${props =>
    props.index <= props.itemsPerRow &&
    css`
      margin-top: 0;
    `}

  /** Remove bottom spacing from the last row **/
    /* ${props =>
      props.index / props.itemsPerRow >
        Math.floor(props.numberOfItems / props.itemsPerRow) &&
      css`
        margin-bottom: 0;
      `} */

  @media ${props => props.theme.breakpoints.small} {
    ${props => !props.itemWidth && `width: 50%;`};
    /* padding: 0 4em; */
    /* text-align: center; */
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

const GridTitle = styled(H3)`
  && {
    /* margin-top: 1em; */
  }
`;

const Grid = ({ fields, ...props }) => {
  const {
    collection,
    itemsPerRow,
    itemAlignment,
    displayTheTitle,
    adminTitle,
    fullWidth,
    customItemWidth,
    showSeparator,
  } = fields;

  const itemWidthArray = customItemWidth && customItemWidth.split(' ');

  return (
    <GridBlock isLastItem={props.index + 1 === props.numberOfBlocks}>
      <Container fullWidth={fullWidth}>
        {adminTitle && displayTheTitle && <GridTitle>{adminTitle}</GridTitle>}
        <GridContainer itemAlignment={itemAlignment}>
          {collection &&
            collection.length &&
            collection.map((gridItem, i) => {
              const { type: itemContentType } = gridItem;

              if (!itemContentType) {
                return false;
              }

              const ContentBlock = contentBlocks[itemContentType];

              return (
                <GridItem
                  gridGap={3}
                  itemsPerRow={itemsPerRow}
                  key={gridItem.adminTitle + '-' + i}
                  // key={sys.id + i}
                  index={i}
                  numberOfItems={collection.length}
                  itemWidth={itemWidthArray && itemWidthArray[i]}
                  showSeparator={showSeparator}
                >
                  {ContentBlock && (
                    <ContentBlock fields={gridItem} fullWidth isNested />
                  )}
                </GridItem>
              );
            })}
        </GridContainer>
      </Container>
    </GridBlock>
  );
};

Grid.propTypes = {
  fields: PropTypes.shape({
    adminTitle: PropTypes.string,
    collection: PropTypes.array,
    displayTheTitle: PropTypes.bool,
    itemsPerRow: PropTypes.number,
    itemAlignment: PropTypes.string,
  }).isRequired,
};

Grid.defaultProps = {
  fields: {
    adminTitle: '',
    collection: [],
    displayTheTitle: false,
    itemsPerRow: 3,
    itemAlignment: 'Top',
    fullWidth: false,
    showSeparator: false,
  },
};

export default Grid;
