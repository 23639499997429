import React, { useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components/macro';
import Header from './Header';
import Navigation from './Navigation';
import HeaderTrigger from './HeaderTrigger';
import SectionHead from '../components/SectionHead';
import Gate from '../components/Gate/Gate.js';
import GateShroud from '../components/Gate/GateShroud.js';
import SocialShare from '../components/SocialShare/SocialShare';
import Progress from '../components/Progress';
/** TODO: move contentblocks to redux state */
import contentBlocks from '../config/contentBlocks';
import { sectionRefsAdd } from '../logic/actions';
/** TODO: move ispaged to redux state */
import config from '../config';
import theme from '../config/theme';

/** This should probably be it's own file, (same with the rest of the markup) */
const SectionWrapper = styled.section`
  position: relative;
  /* min-height: 100vh; */
  background-color: ${({ theme, pageTheme }) => theme[pageTheme].pageColor};

  ${({ customSpacing }) =>
    !!customSpacing?.paddingLarge && `padding: ${customSpacing.paddingLarge}`};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    ${({ customSpacing }) =>
      !!customSpacing?.paddingMedium &&
      `padding: ${customSpacing.paddingMedium}`};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    ${({ customSpacing }) =>
      !!customSpacing?.paddingSmall &&
      `padding: ${customSpacing.paddingSmall}`};
  }

  /** Add an offset so it isn't overlapped by the header when navigating */
  &::before {
    content: '';
    display: block;
    visibility: hidden;
    position: relative;
    z-index: -1;

    /**
      * NOTE: These values are visually dependent on the height of the header.
      * Update them as needed
      */
    padding-top: 6em;
    margin-top: -6em;
  }
`;

/**
 * Create a separate section element for each content item, and create all of
 * the blocks for that section. Also creates the header and navigation, and
 * accounts for paged and inline lightpapers
 */
const Section = props => {
  const {
    isActive,
    isPaged,
    isGateReleased,
    isHeaderUnderFirstBlock,
    item,
    index,
    sectionCount,
    addSectionRef,
  } = props;
  const {
    isGated,
    collection,
    metaTitle,
    metaDescription,
    path,
    theme: pageTheme = 'light',
    customSpacing,
  } = item;

  /**
   * This ref is useless for paged lightpapers, but we can't conditionally
   * create a ref based on isPaged because hooks must be top-level. So this will
   * be created but not used.
   *
   * For inline lightpapers, this will be used to get the DOM's location so we
   * can scroll to it when the navigation is used
   */
  const ref = useRef(null);

  /** Add the ref to the global refs object on mount */
  useEffect(() => {
    addSectionRef({
      [path]: ref.current,
    });
  }, [addSectionRef, path]);

  return (
    /**
     * Assign a primary theme per section based on the Contentful option. This
     * is the light theme by default, but can be extended with any theme that
     * is set up
     */
    <ThemeProvider theme={theme[pageTheme] ?? theme.light}>
      <>
        {/** Header goes above the first section block by default */}
        {!isHeaderUnderFirstBlock && index === 0 && <Header />}

        <SectionWrapper
          ref={ref}
          index={index}
          pageTheme={pageTheme}
          customSpacing={customSpacing}
        >
          {/** Don't render progress bar on the first section (cover) */}
          {index !== 0 && (
            <Progress
              /** Don't show the bar when the gate is blocking the section */
              isActive={isActive && (isGateReleased || !isGated)}
              measureableArea={ref}
            />
          )}
          {/** Render Gate on first gated section only */}
          {(isPaged || props.firstGatedItem === index) &&
            isGated &&
            !isGateReleased && <Gate />}

          {/** Render the meta info if this section has any */}
          {(isActive || isPaged) && (metaTitle || metaDescription) && (
            <SectionHead
              title={metaTitle}
              description={metaDescription}
              pageIndex={index}
            />
          )}
          {/** Render gate on all gated components */}
          {collection.map((block, blockIndex) => {
            const BlockType = contentBlocks[block.contentType];
            const { id, fields } = block;
            /**
             * Render the block if it exists or let us know the block doesn't
             * exist for this content type.
             *
             * Fields is an open-ended prop. It's up to the actual block to define
             * what fields are required for that component (e.g. body, date field,
             * image field, etc)
             */
            return BlockType ? (
              <React.Fragment key={id}>
                {/**
                 * Render the gate shroud only around the rendered blocks to
                 * prevent shrouding the header and nav
                 */}
                <GateShroud isGated={isGated}>
                  <BlockType fields={fields} />
                </GateShroud>

                {/** Always render the header trigger here */}
                {(index === 0 || isPaged) && blockIndex === 0 && (
                  <HeaderTrigger />
                )}

                {/** Render the header under the first block if needed */}
                {(index === 0 || isPaged) &&
                  blockIndex === 0 &&
                  isHeaderUnderFirstBlock && <Header />}

                {(index === 0 || isPaged) && blockIndex === 0 && <Navigation />}
              </React.Fragment>
            ) : (
              console.error(
                `Block type ${BlockType} is not defined using content type ${block.contentType}`
              )
            );
          })}
        </SectionWrapper>
        {/** Render SocialShare at the end of the lightpaper */}
        {index + 1 === sectionCount && <SocialShare />}
      </>
    </ThemeProvider>
  );
};

const mapStateToProps = (state, ownProps) => {
  /** Find the current piece of content based on the id */
  const currentItem = state.content.filter(item => item.id === ownProps.itemId);
  return {
    /** filter() returns an array, and there should only ever be one property */
    item: currentItem[0],
    isHeaderUnderFirstBlock: state.header.isUnderFirstBlock,
    isPaged: config.isPaged,
    isGateReleased: state.isGateReleased,
  };
};

const mapDispatchToProps = dispatch => ({
  addSectionRef: ref => dispatch(sectionRefsAdd(ref)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Section);
