import React from 'react';
import styled from 'styled-components/macro';
import Container from '../Container';

const Iframe = styled.iframe.attrs({
  frameBorder: 0,
})`
  width: 100%;
  margin: 4em auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    min-height: 46em;
  }
`;

const YouTubeVideo = ({ videoId }) => (
  <Iframe
    width="560"
    height="315"
    src={`https://www.youtube.com/embed/${videoId}`}
    title="YouTube video player"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
);

const VimeoVideo = ({ videoId }) => (
  <Iframe
    src={`https://player.vimeo.com/video/${videoId}`}
    title="Vimeo video player"
    width="640"
    height="360"
    allow="autoplay; fullscreen; picture-in-picture"
    allowFullScreen
  />
);

const VideoEmbed = ({ fields = null }) => {
  const { host, videoId } = fields;

  let VideoComponent = null;

  switch (host.toLowerCase()) {
    case 'youtube':
      VideoComponent = YouTubeVideo;
      break;
    case 'vimeo':
      VideoComponent = VimeoVideo;
      break;
    default:
      break;
  }

  return !!videoId && !!VideoComponent ? (
    <Container>
      <VideoComponent videoId={videoId} />
    </Container>
  ) : null;
};

export default VideoEmbed;
