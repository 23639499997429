import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { transparentize, transitions } from 'polished';
import { ParagraphStyles } from './Text';

const FormContainer = styled.div`
  label {
    display: block;
    color: ${props => props.theme.colors.black};
    font-weight: 700;
    font-size: 12px;
    line-height: 1.42;
  }

  input,
  select,
  textarea {
    ${ParagraphStyles};
    font-size: 1.8rem;
    background: ${props => props.theme.colors.white};
    line-height: 0.8;
    padding: 8px 7px 3px;
    width: 100%;
    border: 0;
    margin-bottom: 1em;
    border: 1px solid ${({ theme }) => transparentize(0.75, theme.colors.black)};
    border-radius: 4px;
    ${'' /* appearance: none; */}

    &.error {
      border-color: ${props => props.theme.colors.errorRed};
    }

    &:focus,
    &:active {
      outline: none;
      border-color: ${({ theme }) =>
        transparentize(0.75, theme.colors.brightPrimary)};
    }
  }

  textarea {
    resize: vertical;
  }

  div.hs-submit {
    text-align: center;
  }

  input[type='submit'] {
    display: inline-block;
    border-radius: 3px;
    width: auto;
    font-weight: 700;
    padding: 1rem 3rem;
    margin-top: 1em;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.brightPrimary};
    border-radius: 28px;
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    margin: 0 auto;
    line-height: 1;
    padding-top: 0.75em;
    ${({ theme }) =>
      transitions(
        ['background-color'],
        `${theme.animations.duration} ${theme.animations.easing}`
      )}

    &:hover {
      background-color: ${({ theme }) => theme.colors.brightComplement};
    }
  }

  .submitted-message,
  .legal-consent-container {
    color: ${props => props.theme.colors.white};

    a {
      color: ${props => props.theme.colors.white};
    }
  }

  .legal-consent-container {
    font-size: 0.8em;
  }

  .hs-error-msgs {
    margin: -0.8em 0 1em;
    padding: 0;
    list-style: none;

    label {
      font-style: italic;
      color: ${props => props.theme.colors.errorRed};
    }
  }
`;

const HubspotForm = ({ formId, portalId, sfdcCampaignId, id, ...props }) => {
  const didLoad = useRef(false);
  /**
   * Custom-load the external form script so we can track when it loads or fails
   * and make any customizations needed when it does
   */
  useEffect(() => {
    if (window.hbspt && portalId && formId && id) {
      if (!didLoad.current) {
        /** Quick fix to prevent the form loading many times */
        didLoad.current = true;
        window.hbspt.forms.create({
          region: 'na1',
          portalId,
          formId,
          sfdcCampaignId,
          target: `#${id}`,
          ...props,
        });
      }
    }
  }, [formId, id, portalId, props, sfdcCampaignId]);

  return <FormContainer id={id} className="hubspot-container"></FormContainer>;
};

export default HubspotForm;
