import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { default as C } from '../Container';
import { H2, Eyebrow } from '../Heading';
import { ReactComponent as No1 } from '../../assets/numbers/1.svg';
import { ReactComponent as No2 } from '../../assets/numbers/2.svg';
import { ReactComponent as No3 } from '../../assets/numbers/3.svg';
import { ReactComponent as No4 } from '../../assets/numbers/4.svg';
import { ReactComponent as No5 } from '../../assets/numbers/5.svg';

const NUMBERS = [No1, No2, No3, No4, No5];

const SectionTitleBlock = styled.div`
  margin: 0 0 6rem;
  top: -6rem;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};
  color: ${({ theme }) => theme.colors.white};
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medLarge}) {
    font-size: 27vw;
    min-height: 1em;
    display: flex;
    align-items: center;
    ${'' /* margin-bottom: 0.33em; */}
  }
`;

const Container = styled(C)`
  position: relative;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medLarge}) {
    display: flex;
    flex-flow: column;
  }
`;

const PageNumberContainer = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  width: 8rem;
  margin-bottom: 4em;
  height: 100%;
  display: flex;
  align-items: flex-start;

  svg {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medLarge}) {
    position: absolute;
    width: 0.43em;
    left: -0.4em;
    margin-bottom: 0;
    max-width: 12rem;
  }
`;

const BackgroundImage = styled.div`
  width: 100%;
  padding: 6rem 0 6rem;
  background-image: ${props => `url("${props.imageDesktop}")`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medLarge}) {
    background-image: unset;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    background-position-x: 80%;
  }
`;

const Title = styled(H2)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medLarge}) {
    // font-size: 0.18em;
  }
`;

const Background = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medLarge}) {
    display: none;
  }
`;

const SectionTitle = ({ fields, ...rest }) => {
  const { image, sectionTitle, eyebrow, pageNumber } = fields;

  const PageNumber = NUMBERS[pageNumber - 1];

  return (
    <SectionTitleBlock>
      <BackgroundImage imageDesktop={image}>
        <Background src={image} />
        <Container>
          <PageNumberContainer>
            {pageNumber && <PageNumber />}
          </PageNumberContainer>
          {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
          {sectionTitle && <Title>{sectionTitle}</Title>}
        </Container>
      </BackgroundImage>
    </SectionTitleBlock>
  );
};

SectionTitle.propTypes = {
  fields: PropTypes.shape({
    image: PropTypes.string,
    eyebrow: PropTypes.string,
    sectionTitle: PropTypes.string,
    pageNumber: PropTypes.number,
  }).isRequired,
};

SectionTitle.defaultProps = {
  fields: {
    image: null,
    eyebrow: null,
    sectionTitle: '',
    pageNumber: 1,
  },
};

export default SectionTitle;
