import { createGlobalStyle } from 'styled-components/macro';
import { normalize } from 'polished';
import '../config/fontFace.css';

/**
 * This is a one-time-use method to add global styles.
 *
 * Use for things like body and html styles, and anything that else can't fit as
 * a react component
 */
export default createGlobalStyle`
  ${normalize()}

  * {
    box-sizing: border-box;
  }

  html {
    /** Allows us to easily translate px to rem, e.g. 1.8rem = 18px */
    font-size: 62.5%;
  }

  body {
    font-weight: 400;
    line-height: 1.6;
    overflow-x: hidden;
    font-family: ${({ theme }) => theme.fonts.sansSerif};
    background-color: ${({ theme }) => theme.colors.white};
  }

  code {
    background: #fdf9e0;
    font-size: 0.8em;
    display: inline-block;
    padding: 0 0.2em;
  }

  .desktop-only {
    @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.medLarge}) {
      display: none;
    }
  }
`;
